import React from "react"
import Layout from "../components/Layout/Layout"

export default () => {
  return (
    <Layout>
      <h1>Resume goes here!</h1>
    </Layout>
  )
}
